/* styles.css */
.progress-bar-container {
  width: 100%;
  background-color: var(
    --evvy-cream
  ); /* Assuming evvy-cream is a light color */
  border-radius: 0.5rem;
}

.progress-bar {
  background-color: #074c4c; /* Assuming evvy-pine is a dark color */
  height: 4px;
  transition: width 0.3s ease-in-out;
}
