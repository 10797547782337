/* custom styles if you need them. use tailwind when you can though! */

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  /* from google fonts in index.html */
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

/* prevent blue highlight box for tabindex div items for accessibility */
div {
  outline: none;
}

/* import our serif font */
@font-face {
  font-family: Marist;
  src: url("./fonts/marist/Marist-Book.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/marist/Marist-Book.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/marist/Marist-Book.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/marist/Marist-Book.woff")
      format("woff");
  /* Pretty Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MaristItalic;
  src: url("./fonts/marist-italic/Marist-BookItalic.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/marist-italic/Marist-BookItalic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/marist-italic/Marist-BookItalic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/marist-italic/Marist-BookItalic.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.h1 {
  letter-spacing: -1px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.serif {
  font-family: Marist, serif;
  font-weight: normal;
  font-style: normal;
}
h4.sansSerif,
.t1,
.t2,
.t3,
.t4,
.title,
.b2 {
  font-family: "Inter", sans-serif;
}

.t2.serif {
  font-family: Marist, serif;
  font-weight: 600;
}

body {
  color: #11161a;
  line-height: 1.063rem;
  /* 17px */
}

.serif.italic {
  font-family: MaristItalic;
  font-weight: normal;
  font-style: normal;
}

[disabled] {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

a.underline {
  text-decoration: none;
  position: relative;
}

a.underline:not(.persistSize) {
  text-decoration: none;
  position: relative;
  font-size: 12px;
}

a.underline:after {
  content: "";

  width: 100%;
  position: absolute;
  left: 0;
  bottom: -1px;

  border-width: 0 0 1px;
  border-style: solid;
  border-color: initial;
}

.underlineStyle:after {
  content: "";

  width: 100%;
  position: absolute;
  left: 0;
  bottom: -1px;

  border-width: 0 0 2px;
  border-style: solid;
  border-color: initial;
}

.dot-sm {
  width: 5px;
  height: 5px;
}

.dot {
  width: 10px;
  height: 10px;
}

.dot-small {
  margin-top: 6.5px;
  width: 7px;
  height: 7px;
}

.dot-lg {
  width: 26px;
  height: 26px;
}

.dot-legend {
  width: 18px;
  height: 18px;
}

.slide {
  overflow: hidden;
  max-height: 2000px;
  height: auto;
  /* transition:  max-height 1s ease, height 1s ease; */
}

.slide.hide {
  max-height: 0;
  height: auto;
}

.uppercase {
  text-transform: uppercase;
}

.capitalizeFirst:first-letter {
  text-transform: capitalize;
}

h1 {
  font-size: 4.25rem;
  /* 60px */
}
h2 {
  font-size: 2.5rem;
  /* 40px */
}
h3 {
  font-size: 2rem;
  /* 32px */
}
h4 {
  font-size: 1.5rem;
  /* 24px */
}
h5 {
  font-size: 1.85rem;
  /* 26px */
  min-height: 1.85rem;
  /* preserve height on load */
}

/* Font-sizes for mobile */
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.75rem; /* 40px */
  }
  h2 {
    font-size: 2.25rem;
    /* 32px */
  }
  h3 {
    font-size: 2rem;
    /* 24px */
  }
  h4 {
    font-size: 1.25rem;
    /* 20px */
  }
}

h6 {
  font-size: 1.714rem;
  /* 24px */
  min-height: 1.85rem;
  /* preserve height on load */
}

.t1 {
  font-size: 1.289rem;
  /* 18px */
  line-height: 1.571rem;
  /* 22px */
  font-weight: 500;
}

.t2 {
  font-size: 1.289rem;
  /* 18px */
  line-height: 1.571rem;
  /* 22px */
}

.t3 {
  font-size: 1.143rem;
  /* 16px */
  line-height: 1.423rem;
  /* 20px */
  font-weight: 500;
}

.t4 {
  font-size: 0.929rem;
  /* 13px */
  line-height: 1.423rem;
  /* 20px */
  letter-spacing: 0.05em;
  font-weight: 600;
  text-transform: uppercase;
}

.t5 {
  font-size: 0.714rem;
  /* 10px */
  line-height: 0.864rem;
  /* 12px */
  text-transform: capitalize;
  font-weight: 600;
}

.title {
  font-size: 1.143rem;
  /* 16px */
  line-height: 1.714rem;
  /* 24px */
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  /* color: #11161A; */
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}
.b0 {
  font-size: 1.423rem;
  /* 20px */
  line-height: 1.571rem;
  /* 22px */
}

.b1 {
  font-size: 1.143rem;
  /* 16px */
  line-height: 1.714rem;
  /* 24px */
}

.b2 {
  font-size: 1rem;
  /* 14px */
  line-height: 1.423rem;
  /* 20px */
}

.b3 {
  font-size: 0.858rem;
  /* 12px */
  line-height: 1rem;
  /* 14px */
}

.caption {
  font-size: 0.857rem;
  /* 12px */
  line-height: 1.071rem;
  /* 15px */
}

.content {
  color: #11161a;
  font-size: 1rem;
  /* 14px */
  line-height: 1.214rem;
  /* 17px */
}

button:not(.linkedText, .arrow, .rectangular, .arrow-pill, .unset) {
  text-transform: uppercase;
  font-size: 12px;
  /*font-size: 0.75rem;
	font-weight: 700;*/
}

/* to break something out of layout container */
.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/*
Hide scrollbar when we are using a full-width class on the page
b/c the width changes depending on if there's a scrollbar
*/
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* results page */

.align-start {
  align-self: start;
}

.animate-wiggle {
  transform-origin: center center;
  transition-property: scale 250ms;
  animation: scale 2s infinite alternate ease-in-out;
  animation-delay: inherit;
}

/* plan */
#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

#tooltip[data-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-placement^="right"] > #arrow {
  left: -4px;
}

#carousel::-webkit-scrollbar {
  height: 4px;
  /* width of the entire scrollbar */
}

#carousel::-webkit-scrollbar-track {
  background: rgba(65, 144, 122, 0.1);
  /* color of the tracking area */
}

#carousel::-webkit-scrollbar-thumb {
  background-color: #41907a;
  /* color of the scroll thumb */
  border-radius: 0;
  /* roundness of the scroll thumb */
  border: none;
  /* creates padding around scroll thumb */
}

/* firefox */
#carousel {
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #41907a rgba(65, 144, 122, 0.1);
  /* scroll thumb and track */
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.hiddenMarginEl {
  margin-top: -50px;
  height: 50px;
}

/* results headers that should shrink on mobile */
h1.mobileShrink {
  font-size: 3.25rem;
  /* 45px */
  margin: 0 0.75rem;
}

/* results headers that should shrink on mobile - no margin in new results */
h1.mobileShrinkV2 {
  font-size: 3.25rem;
  /* 45px */
}

@media (min-width: 768px) {
  h1.mobileShrink {
    font-size: 4.25rem;
    /* 60px */
    margin: 0;
  }

  .hiddenMarginEl {
    margin-top: -285px;
    height: 285px;
  }
}

/* ref https://stackoverflow.com/questions/22666063/how-to-fade-the-edge-of-a-div-with-just-css */
/* fades table bottom to white on mobile */
.fadeAfter::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
  width: 100%;
  height: 4em;
}

.animate-grow {
  -webkit-animation: scaleup 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: scaleup 2s;
  /* Firefox < 16 */
  -ms-animation: scaleup 2s;
  /* Internet Explorer */
  -o-animation: scaleup 2s;
  /* Opera < 12.1 */
  animation: scaleup 2s;
}

@keyframes scaleup {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Firefox < 16 */
@-moz-keyframes scaleup {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes scaleup {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Internet Explorer */
@-ms-keyframes scaleup {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.specialGrid {
  grid-template-columns: 1.1fr 0.8fr 1.1fr;
  grid-template-rows: [top] 2fr [middle] 8fr [bottom] 1fr;
}

.animatedBarOne {
  animation: show-bar-one-vertical 3s ease infinite;
}

.animatedBarTwo {
  animation: show-bar-one-vertical 3s ease infinite;
  animation-delay: 1.5s;
}

/* Bar Graph Vertical Animations */
@keyframes show-bar-one-vertical {
  0% {
    height: 0;
    transform: translateY(100%);
  }

  50% {
    height: 100%;
    transform: translateY(0%);
  }

  100% {
    height: 0;
    transform: translateY(100%);
  }
}

.trend-down {
  color: #db6363;
}

.trend-down-bg {
  background-color: #f8e0e0;
}

.trend-up {
  color: #41907a;
}

.trend-up-bg {
  background-color: #bfe3d9;
}

.trend-neutral {
  color: #11161a;
}

.trend-neutral-bg {
  background-color: #e7e7e8;
}

.trend-row:first-of-type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.trend-row:last-of-type,
.trend-shortlist {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
